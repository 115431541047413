@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.language-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none !important;
    @include heading-font();
    font-weight: 500;
    @include font-size(1.6);
    color: $cool-grey-11;
  }
  img {
    height: 24px;
    width: 24px;
  }
  .MuiInput-underline:before {
    border: none;
  }
  .MuiSelect-select.MuiSelect-select {
    padding-right: 30px;
    padding-left: 10px;
  }
  .MuiInput-underline:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid $italy-teal !important;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    transform: scaleX(1);
  }
  .MuiInput-underline:before {
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.3s ease;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiSelect-icon {
    position: relative;
    margin-left: -30px;
  }
}
.MuiPopover-root {
  .MuiMenu-paper {
    ul {
      padding-top: 0;
      padding-bottom: 0;
      li {
        padding: 0;
        a {
          padding: 6px 16px;
          @include heading-font();
          @include font-size(1.6);
          font-weight: 500;
          color: $cool-grey-11;
          width: 100%;
        }
      }
    }
  }
  // Added to override inline style and ensure language selector is above Cookiebot modal
  z-index: 2147483632 !important;
}
