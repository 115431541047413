@import '../../assets/styles/_variables';
@import '../../assets/styles/_mixins';

.accordion-wrapper {
  .accordion {
    .MuiAccordion-root {
      background-color: #f8f8f8;
      margin-bottom: 10px;
      border: none;
      border-radius: 0;
      box-shadow: none;

      .MuiTypography-root {
        color: #363533;
      }
      .MuiAccordionDetails-root {
        background-color: white;

        padding: 16px 16px;
      }
    }
    .MuiSvgIcon-root {
      color: #363533;
    }

    .MuiAccordionSummary-root {
      &:focus {
        outline: 2px auto $italy-blue;
        outline-offset: 2px;
      }
      .MuiTypography-root {
        font-weight: 700;
      }

      &.Mui-expanded {
        background-color: $italy-blue;
        margin: 0;
        .MuiSvgIcon-root {
          color: white;
        }

        .MuiTypography-root {
          color: white;
        }
        &:focus {
          outline: 2px auto $italy-blue;
          outline-offset: 2px;
        }
      }
      .MuiAccordionSummary-expandIcon {
        transition: none !important;
        transform: none !important;
        &.Mui-expanded {
          .MuiIconButton-label {
            .expander {
              path {
                transform-origin: 50% 50% !important;
                transform: rotate(180deg) !important;
              }
            }
          }
        }
      }
      .MuiAccordionSummary-content {
        &.Mui-expanded {
          margin: 0px 0;
        }
      }
    }
    .MuiCollapse-wrapperInner {
      background-color: white;
    }
  }
}
