@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

footer {
  border-bottom: 10px solid $accent-alpha;
  .footer-fullwidth-wrapper {
    margin-top: 40px;
    width: 100%;
    max-width: 100%;
    .footer-top-bar-container {
      background-color: $primary-purple;
      color: $cool-grey-11;
      padding: 0;
      @include min-screen(medium) {
        padding: 24px 96px;
      }
      .footer-logo-wrapper {
        text-align: center;
        padding: 24px 0;
        @include min-screen(small) {
          padding: 40px 0;
        }
        @include min-screen(medium) {
          text-align: left;
          padding: 0;
        }
        & img {
          width: 156px;
        }
      }
      .footer-top-bar {
        justify-content: space-between;
        align-items: center;
      }
    }
    .footer-search-field-label {
      @include heading-font();
      @include font-size(2);
      color: $cool-grey-11;
    }
    .footer-legal-container {
      color: #fff;
      padding: 0;
      background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
      @include min-screen(small) {
        text-align: center;
      }
      .footer-icon-left {
        background: url(../../assets/images/iconography/Italy/iconography-footer-bottom-left.svg);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .footer-icon-right {
        background: url(../../assets/images/iconography/Italy/iconography-footer-bottom-right.svg);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .footer-legal-spacing {
        padding: 12px 0;
        @include min-screen(small) {
          padding: 24px 0;
        }
        .footer-legal-grid {
          padding: 12px;
          .linklistcontainer {
            .linklist {
              list-style: none;
              margin: 0;
              padding: 0;
              .linklistitem {
                margin: 0 10px 0 0;
                display: block;
                @include max-screen(medium) {
                  padding: 15px 0 15px 0;

                }
                @include min-screen(small) {
                  display: inline-block;
                }
                &::after {
                  content: none;
                }
                a {
                  @include body-font();
                  @include font-size(1.8);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .social-icon-wrapper {
      display: flex;
      align-content: center;
      justify-content: space-around;
      padding: 24px 0;
      @include min-screen(medium) {
        justify-content: flex-end;
        padding: 0;
      }
      div {
        @include min-screen(medium) {
          // padding-left: 14px;
        }
        a {
          margin-right: 20px;
          display: inline-block;
          height: 40px;
          width: 40px;
          &:last-child {
            margin-right: 0;
          }
          img {
            height: 40px;
            width: 40px;
            color: $white;
          }
        }
      }
    }
    .footer-copyright-container {
      padding: 12px;
      @include max-screen(medium) {
        text-align: center;
      }
      span {
        color: $white;
      }
    }
    .footerSiteLinks {
      min-height: 351px;
      color: $cool-grey-11;
      padding: 0 48px 96px 48px;

    .four-column-content-row .MuiGrid-item{
      padding:18px 9px;
    }

      @include min-screen(small) {
        padding: 0 64px 96px 64px;
      }
      @include min-screen(medium) {
        padding: 0 96px 88px 96px;
      }
      .linklistcontainer {
        .linklist {
          margin: 0;
          padding: 0;
          .linklistitem {
            a {
              margin-bottom: 5px;
              display: block;
              @include body-font();
              @include font-size(1.8);
              color: $cool-grey-11;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
                color: #bc3dc9;
              }
              &:active {
                color: $upmc-purple;
              }
              &:visited {
                text-decoration: none;
                color: $accent-purple;
              }
              &:focus {
                color: $cool-grey-11;
                outline: 2px solid #bc3dc9;
                outline-offset: 0px;
              }
              &.footer-sitemap-viewall {
                display: block;
                text-decoration: underline;
                margin-top: 10px;
              }
              &.footer-sitemap-header {
                padding: 0;
                @include heading-font();
                color: $cool-grey-11;
                @include font-size(2);
                margin-bottom: 16px;
                display: block;
              }
            }
          }
        }
      }
      .MuiContainer-maxWidthLg {
        &:last-child {
          margin-top: 0;
        }
      }
      .content-row + .content-row .MuiGrid-item {
        margin-top: 0;
      }
      @include max-screen(medium) {
        .content-row {
          &:first-child {
            .MuiGrid-item {
              &:first-child {
                margin-top: 40px;
              }
            }
          }
          .MuiGrid-item {
            margin-top: 0;
          }
        }
      }
    }
  }
  .footer-bottom-border {
    border-bottom: 10px solid $accent-alpha;
    margin: 2px 0 0 0;
  }
  .footer-search-wrapper {
    text-align: center;
    background-color: $alto-grey;
    height: 68px;
    display: flex;
    align-items: center;
    padding: 24px 20px;
    box-sizing: content-box;
    .search-box-container {
      width: 100%;
      .search-field-wrapper {
        border: none;
        .search-field-border {
          border: none;
          padding: 0px;
          background: none;
          .headless-search-field-input {
            width: calc(100% - 60px);
          }
          .site-search-reset-button{
            display: none;
          }
        }
        &:focus-within {
          .search-field-border{
            .search-field-frame{
              .site-search-reset-button{
                display: inline-block;
              }
              .headless-search-field-input{
                width: calc(100% - 99px);
              }
            }
          }
        }
      }
    }
    @include min-screen(medium) {
      border-radius: 34px;
      text-align: left;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
  .footer-search-wrapper-grid {
    align-items: center;
    .footer-search-field-label {
      margin-bottom: 10px;
      display: block;
      @include min-screen(medium) {
        margin-bottom: 0px;
      }
    }
  }
}

@include min-screen(small) {
  footer {
    .footer-legal-grid {
      .linklistcontainer {
        .linklist {
          .linklistitem {
            display: inline-block;
            &::after {
              content: '|';
              position: relative;
              color: #fff;
              padding-left: 10px;
            }
            &:last-child {
              margin-right: 0;
              &::after {
                content: '';
              }
            }
            a {
              color: $white;
              text-decoration: underline;
              &:hover {
                color: $accent-purple;
              }
            }
          }
        }
      }
    }
  }
}