@import './_variables.scss';

.skip-to-content {
  a {
    position: absolute;
    z-index: 90000;
    top: -1000px;
    left: 0;

    &:focus {
      top: 0;
    }
  }
}

@mixin outline-accessability {
  outline: 2px solid $accent-alpha;
  outline-offset: 2px;
}

:focus {
  @include outline-accessability();
}

input,
textarea,
button {
  :focus {
    @include outline-accessability();
  }
}
