@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.hero-banner-with-search {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  @include max-screen(medium) {
    grid-template-rows: inherit;
  }
  .text-column {
    grid-area: 1 / 1 / 4 / 3;
    width: 80%;
    margin: 50px 0 0 85px;
    @include max-screen(medium) {
      grid-area: 2/1/3/5;
      margin: 0px auto 0;
    }
    @include max-screen(small) {
      width: 100%;
      padding: 0;
    }
    .title {
      margin-left: 0;
      margin-top: 0px;
    }
    .additional-text {
      padding: 0 15px 15px 0;
      margin-bottom: 50px;
      @include max-screen(small) {
      }
      p {
        font-family: $catamaran !important;
        @include font-size(2);
        color: $cool-grey-11;
      }
      span {
        font-family: $catamaran !important;
      }
    }
    .title,
    .additional-text {
      @include max-screen(small) {
        padding: 0px 15px 0px 15px;
      }
    }
    .title,
    .additional-text {
      @include min-screen(small) {
        margin-left: 15px;
      }
    }

    
    .search-field-container {
      background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
      @include min-screen(medium) {
        width: 100%;
      }
      @include min-screen(medium) {
        border-bottom: none;
        margin-left: 15px;
      }
      .hero-search-overlay-container{
        position: relative;
        background: url(../../assets/images/iconography/Italy/iconography-searchbox.svg);
        background-position: -40% 100%;
        background-repeat: no-repeat;
        background-size: 82% 100%;
        .search-border-spacer {
          padding: 20px 10px;
          border-bottom: 1px solid #fff;
          text-align: center;
          @include min-screen(medium) {
            border-bottom: none;
            border-left: 1px solid #fff;
            padding: 25px;
          }
          span {
            @include h4();
            display: block;
            color: #fff;
            margin: 0;
            padding: 0 0 15px 0;
            @include max-screen(medium) {
              text-align: center;
            }
          }
          .component-container {
            background-color: #fff;
            width: 80%;
            margin: auto;
            border-radius: 50px;
            .search-box-container {
              .search-field-wrapper {
                border: none;
                .search-field-border {
                  border: none;
                  padding: 0px;
                  .headless-search-field-input {
                    width: calc(100% - 60px);
                  }
                  .site-search-reset-button {
                    display: none;
                  }
                }
              &:focus-within{
                .headless-search-field-input {
                  width: calc(100% - 99px);
                }
                .site-search-reset-button {
                  display: inline-block;
                }
              }
  
              }
            }
            @include max-screen(medium) {
              margin: 0 auto;
              max-width: 250px;
              .site-search,
              .full-width-helper,
              .site-search-form-wrapper,
              .globalSearch {
                max-width: 250px;
              }
              .search-box-container {
                .search-field-wrapper {
                  border: none;
                  .search-field-border {
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .image-container {
    grid-area: 1 / 1 / 4 / 5;
    z-index: -2;
    @include max-screen(medium) {
      grid-area: 1/1/2/5;
    }
    img {
      width: 100%;
    }
  }
}
