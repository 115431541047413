// BREAKPOINTS (Media Queries)  =============================================== *
$breakpoints: (
  'tiny': 0px,
  'small': 600px,
  'medium': 960px,
  'large': 1280px,
  'xlarge': 1921px,
);

// Colors  =============================================== *
//Base
$white: #fff;
$black: #000;

// Primary
$primary-purple: #4d104a;
$upmc-purple: #771b61;
$primary-green: #228747;

//Italy Specific
$accent-alpha: #58b7b3;
$italy-blue: #0047bb;
$italy-teal: #4eaeaa;
$italy-dark-blue: #003284;

// Accent
$accent-purple: #904199;
$accent-green: #1c723c;
$accent-dark-purple: #2b0622;

// Greys
$grey: #efefef;
$cool-grey-1: #dbd9d6;
$cool-grey-4: #bdbbbb;
$cool-grey-5: #cccccc;
$cool-grey-7: #9a9a9b;
$cool-grey-9: #666d70;
$cool-grey-11: #363533;
$alto-grey: #dedede;
$concrete: #f3f3f3;
$white-smoke:#F8F8F8;
$iron-grey: #e0e1e2;
$silver-foil: #afb0b1;

// Alert System
$alert-red: #bb2253;
$alert-orange: #f47721;
$alert-yellow: #fbaf5d;
$alert-green: #228747;

//Breadcrumbs
$breadcrumb-current: #1b1c1d;

//Tertiary Colors
$rose-red: #bb2253;
$light-orange: #fbaf5d;
$medium-cyan: #0092b4;
$dark-cyan: #00819f;
$dark-purple: #3c1053;
$light-purple: #c7b4e2;
$light-blue: #47c6e6;
$yellow: #cfdb00;
$dark-orange: #f47721;
$coral: #f37f89;

//Purples
$deep-mauve : #bc3dc9;
