@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';
@import '../../assets/styles/_buttons.scss';
@import '../../assets/styles/_accessibility.scss';

.physician-details {
  .physician-banner-wrapper {
    display: block;
    background-color: #ededed;
    padding: 30px 40px;

    @include max-screen(large) {
      max-width: 100%;
    }
    @include min-screen(small) {
      max-width: 100%;
      margin-right: 18px;
    }

    .physician-banner-img {
      text-align: center;
      margin-bottom: 18px;

      @include min-screen(large) {
        background: none;
      }
      img {
        @include max-screen(large) {
          max-width: 100%;
        }
        @include min-screen(large) {
          max-width: 100%;
        }
      }
    }

    .physician-banner-details {
      width: 100%;
      .physician-banner-details-innner {
        .physician-contact-subheading {
          font-weight: 700;
          margin-top: 18px;
          margin-bottom: 8px;
        }

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
  .physician-biography {
    .physician-biography-wrapper {
      padding: 0 18px;
      @include max-screen(large) {
        padding: 0;
        margin: -20px 0 30px 0%;
      }
      @include min-screen(large) {
        padding: 0;
        margin: -20px 0 30px 18px;
      }

      .physician-schedule-subheading,
      .physician-subSpecialty-subheading,
      .physician-biography-heading,
      .physician-research-subheading,
      .physician-membership-subheading,
      .physician-qulifications-subheading,
      .physician-insurance-subheading,
      .physician-resume-subheading,
      .physician-specialty-subheading {
        @include heading-font();
        @include font-size(2.4); //mobile
        display: block;
        margin: 20px 0 0 0;
      }
      .physician-schedule-subheading,
      .physician-research-subheading,
      .physician-membership-subheading {
        margin-bottom: 0;
      }
      .physician-schedule-subheading {
        @include max-screen(small) {
          margin-top: 40px;
        }
      }
      .physician-locations-list,
      .schedule-location-statement,
      .physician-subSpecialty-list,
      .physician-research-list,
      .physician-resume-list,
      .physician-membership-list {
        list-style-type: none;
        padding: 0;
      }
      .physician-locations-list {
        .schedule-days-statement {
          margin-bottom: 20px;
        }
        li {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .schedule-location-statement {
        font-weight: bolder;
      }

      .physician-qualifications-details {
        margin-top: 18px;
        margin-bottom: 0;
      }

      p,
      .physician-biography-details {
        margin-left: 0;
        margin-top: 18px;
        p {
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .physician-resume-details {
        display: inline-block;
        margin-top: 18px;
        font-weight: inherit;
      }
      .physician-specialty-details {
        margin-top: 18px;
      }
      .physician-icon {
        background-color: #efefef;
        padding: 0.5em 0.5em;
        line-height: 2em;
        border-radius: 0.2em;
        text-decoration: none;
        vertical-align: middle;
        margin-right: 5px;
      }
      .physician-schedule {
        margin-top: 18px;
      }
    }
  }
  .display-name {
    @include heading-font();
    @include font-size(3.6); //mobile
    margin-left: 0px;
    margin-bottom: 0px;
    display: inline-block;
  }
  .specialty-container {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    :first-child {
      margin-top: 9px;
    }

    :last-child {
      margin-bottom: 18px;
    }
  }
  .physician-specialty-subheading {
    font-family: $montserrat;
    color: $primary-purple;
    @include font-size(2.8);
    margin-right: 5px;
    display: inline-block;
    &:not(:last-child)::after {
      content: ', ';
    }

    @include max-screen(small) {
      margin: 0 0;
      display: block;
    }
  }
  .one-column-sublayout {
    padding: 0 18px;
    @media (min-width: 1263px) {
      padding: 0 100px;
    }
  }
  .cta {
    display: block;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .physician-book-visit {
    text-align: center;
  }
  .physician-phone {
    font-weight: inherit;
    display: inline-block;
  }
  .physician-email {
    display: inline-block;
    margin-top: 18px;
    font-weight: inherit;
    text-decoration: underline;

    @include max-screen(medium) {
      width: 180px;
      margin-bottom: 32px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
