@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_typography.scss';
@import '../../../assets/styles/_accessibility.scss';

.headless-search-page {
    .search-box-container {
      width: 100%;
      max-width: 510px;
      background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
      box-shadow: 10px 10px 0px 0px $alto-grey;
      @include max-screen(medium) {
        box-shadow: 0px 10px 0px 0px $alto-grey;
        max-width: none;
        width: 100vw;
        border-bottom: none;
        margin-bottom: 50px;
        margin-left:  -18px;
      }
      .hero-search-overlay-container{
        position: relative;
        background: url(../../../assets/images/iconography/Italy/iconography-searchbox.svg);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 50%;
        height: 100%;
        width: 100%;
        @include max-screen(medium) {
          background-size: 75%;
        }
        .search-border-spacer {
          padding: 20px 10px;
          text-align: center;
          border-bottom: none;
          padding: 40px;
          .search-field-wrapper {


            border: none;
            border-left: none;

            @include min-screen(small) {
              border-bottom: none;
              max-width: 768px;
            }
            .search-field-border {
              border: none;
              padding: 0px;
              background: none;
              .search-field-frame {
                box-sizing: border-box;
                border: none;
                .headless-search-field-input {
                  width: calc(100% - 60px);
                }
                .site-search-reset-button {
                  display: none;
                }
              }
            }
            &:focus-within{
              .search-field-border {
                .headless-search-field-input {
                  width: calc(100% - 99px);
                }
                .site-search-reset-button {
                  display: inline-block;
                }
              }
            }
          }
        }
      }
    }
  // Tab Styles
  .search-tab {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 20px 0;
    width: 100%;
    margin-top: 20px;
    opacity: 1;
    .MuiButtonBase-root{
      min-width: 0;
    }

    .MuiButtonBase-root.Mui-disabled {
      width: 0px;
    }
    .MuiTab-textColorInherit {
      opacity: 1;
    }
    cursor: pointer;
    a {
      display: inline-table;
      background-color: $cool-grey-7;
      margin-right: 4px;
      color: #000;
      text-decoration: none;
    }
    span {
      font-weight: 700;
      font-style: bold;
    }
    .active {
      background-color: $italy-blue;
      margin: 0px 9px 0px 5px;
      span {
        color: white;
      }
    }
    .inactive {
      background-color: $white-smoke;
      margin-right: 4px;
      span {
        color: #000;
      }
    }
  }
  // Query Summary Styles
  .search-title {
    margin: 15px 0 0 0;
    .query-summary {
      margin-top: 40px;
      a {
        cursor: pointer;
      }
    }
  }
  // Refine Search Styles
  .sort-filter {
    // TODO: Sort drop down looks odd at large screens floated left use max-width element to control 768px or 1150px.
    // max-width: 768px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 20px 0;
    @include min-screen(medium) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
    display: flex;

    width: 100%;
    .tab-container {
      flex-grow: 1;
      button {
        cursor: pointer;
        background: none;
        border: none;
        background: none;
        border: none;
        width: inherit;
        border-radius: 5px;
        padding: 0 15px;
        margin: 0 5px 0 0;
        &:disabled {
          cursor: inherit;
          color: $white;
          background-color: $italy-blue;
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }
      }
    }
    p {
      display: inline-block;
      margin: 0;
    }
    .sort {
      margin: 20px 0 0 0;
      @include min-screen(medium) {
        margin: 0;
      }
      justify-self: flex-end;
      select {
        @include body-font();
        @include font-size(1.8);
        padding: 0 10px;
        border: 1px solid $iron-grey;
        border-radius: 5px;
        box-sizing: border-box;
        resize: vertical;
        color: #000;
        background-color: $concrete;
        margin: 0 0 0 10px;

        &:focus {
          border: 1px solid $accent-purple;
          outline: none;
          box-shadow: none;
        }
        ::placeholder {
          color: $cool-grey-7;
        }
      }
    }
  }
  // Search Results Styles
  .mainresults-area {
    margin-top: 40px;
    .result-list {
      .result-item {
        padding: 0 20px 0 0;
        margin: 25px 0 0 0;
        width: 80%;
        @include max-screen(medium) {
          width: auto;
        }
        &:last-child {
          margin: 40px 0 40px 0;
        }
        .search-result-name {
          a {
            @include h4();
            overflow-wrap:break-word;
            text-decoration: none;
            font-weight: 700;
          }
        }
        .search-result-description {
          color: $cool-grey-11;
          margin: 10px 0px 10px 0px;
        }
        .search-result-link {
          padding-bottom: 20px;
          display: block;
          a {
            word-break: break-all;
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
    }
  }
  .hr{
    border: none;
    height: 2px;
    background-color: $grey;
  }
  //Footer Area Styles
  .footer-area {
    margin-top: 20px;
    .pager {
      button {
        cursor: pointer;
        min-width: 40px;
        border-radius: 5px;
        font-weight: 700;
        &:disabled {
          cursor: inherit;
          color: $cool-grey-11;
        }
      }
      .search-result-pagination{
        .MuiPagination-ul{
          justify-content: center;
        }
      }
    }
  }
}
