@import '../../../assets/styles/mixins.scss';
@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_typography.scss';
@import '../../../assets/styles/_accessibility.scss';

.headless-consultants-search-page {
  .search-box-container {
    width: 100%;
    max-width: 510px;
    background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
    box-shadow: 10px 10px 0px 0px $alto-grey;
    @include max-screen(medium) {
      box-shadow: 0px 10px 0px 0px $alto-grey;
      max-width: none;
      width: 100vw;
      border-bottom: none;
      margin-bottom: 50px;
      margin-left: -18px;
    }
    .hero-search-overlay-container {
      position: relative;
      background: url(../../../assets/images/iconography/Italy/iconography-searchbox.svg);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 50%;
      height: 100%;
      width: 100%;
      .search-border-spacer {
        padding: 20px 10px;
        text-align: center;
        @include min-screen(medium) {
          border-bottom: none;
          padding: 25px;
        }
        .search-field-wrapper {
          border: none;
          border-left: none;

          @include min-screen(small) {
            border-bottom: none;
            // max-width: 768px;
          }
          .search-field-border {
            border: none;
            padding: 0px;
            background: none;
            .search-field-frame {
              box-sizing: border-box;
              border: none;
              .headless-search-field-input {
                width: calc(100% - 60px);
              }
              .site-search-reset-button {
                display: none;
              }
            }
          }
          &:focus-within {
            .search-field-border {
              .headless-search-field-input {
                width: calc(100% - 99px);
              }
              .site-search-reset-button {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  .two-column-content-row {
    .MuiGrid-item {
      padding: 0;
    }
  }
  // Query Summary Styles
  .search-title {
    margin: 15px 0 0 0;
  }

  // Facet Styles
  .facetDesktop {
    @include max-screen(medium) {
      display: none;
    }
    .facet {
      padding: 18px;
      margin-bottom: 5px;
      padding-bottom: 2px;
    }
    div {
      margin-left: 5px;
    }
    input[type='checkbox'] {
      margin-left: -18px;
    }
  }

  .facetMobile {
    @include min-screen(medium) {
      display: none;
    }
    @include max-screen(medium) {
      display: block;
    }
    div {
      margin-left: 5px;
    }
    input[type='checkbox'] {
      margin-left: -18px;
    }
  }
  .facet {
    padding: 18px;
    h3 {
      margin-top: 0;
      margin-bottom: 10px;
      margin-left: -18px;
    }
    .show-more-less-wrapper {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
  // Search Results Styles
  .mainresults-area {
    .result-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      .result-item {
        .search-result-name {
          a {
            @include h4();
            text-decoration: none;
          }
        }
        .search-result-description {
          color: $cool-grey-9;
        }
        .search-result-link {
          padding-bottom: 20px;
          display: block;
          a {
            word-break: break-all;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    @include max-screen(medium) {
      padding: 0;
    }
    .consultant-summary {
      margin-bottom: 15px;
      @include max-screen(medium) {
        margin-bottom: 30px;
      }
    }
    .search-result-pagination {
      ul {
        margin-top: 35px;
        justify-content: center;
      }
    }
    @include min-screen(medium) {
      width: 100%;
      margin-left: 0;
    }
    .consultant-profile-wrapper {
      padding: 20px;
      border: 1px solid $cool-grey-5;
      border-radius: 4px;
      box-sizing: border-box;
      width: 100%;
      margin-top: 20px;
      background: #ffffff 0% 0% no-repeat padding-box;
      padding: 30px 40px;

      .MuiGrid-item {
        padding: 0;
        margin-bottom: 0;
      }
      .display-name {
        margin: 0;
        @include max-screen(medium) {
          font-size: 36px;
        }
        @include max-screen(small) {
          font-size: 32px;
        }
      }
      @include max-screen(small) {
        margin-bottom: 20px;
      }
      @media (min-width: 960px) {
        max-width: 100%;
        margin-left: 0;
      }

      display: flex;
      @include min-screen(small) {
        flex-direction: row;
      }
      .image-container {
        display: block;
        img {
          border: none;
          width: 168px;
          @include max-screen(small) {
            width: 100%;
          }
        }
      }
      .details-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .contact-location-container {
          margin-top: 15px;
        }

        h6,
        .locations-heading .contact-heading {
          margin-top: 10px;
          margin: 0;
          color: $cool-grey-11;
          display: block;
          line-height: 1.5;
          @include body-font();
          font-weight: bold;
        }
        h3,
        .specialty-heading {
          margin: 0;
          color: $cool-grey-11;
          display: block;
          line-height: 1.5;
          font-weight: 700;
          font-size: 20px;
          .speciality {
            &:not(:last-child)::after {
              content: ', ';
            }
          }
        }
        .consultant-location {
          display: block;
          @include body-font();
          p {
            margin: 0;
          }
        }
        .phone-list {
          list-style-type: none;
          margin: 5px 0 0 0;
          padding: 0;
          a {
            text-decoration: underline;
            color: $italy-blue;
          }
        }
        a {
          text-decoration: none;
          font-weight: inherit;
          color: $primary-purple;
          button {
            margin: 8px 0;
            width: 166px;
            font-weight: 600;
          }
        }
        @include min-screen(small) {
          padding-left: 32px;
        }
        @include max-screen(medium) {
          word-wrap: break-word;
        }
        .view-profile {
          text-align: right;
          align-self: flex-end;
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
          flex-grow: 1;
        }
      }
    }

    .consultant-profile-wrapper:nth-child(even) {
      background: #f4f7fc 0% 0% no-repeat padding-box;
    }
  }
  //Footer Area Styles
  .footer-area {
    margin-top: 20px;
    .pager {
      button {
        cursor: pointer;
        min-width: 40px;
        border-radius: 5px;
        font-weight: 700;
        &:disabled {
          cursor: inherit;
          color: $cool-grey-11;
        }
      }
      .search-result-pagination {
        .MuiPagination-ul {
          justify-content: center;
        }
        .MuiPaginationItem-root {
          margin: 0px;
        }
      }
    }
  }
}
